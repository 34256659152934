<template>
  <div>
    <loading v-if="isLoading" />
    <error v-else-if="isError" />
    <content-not-view v-else-if="!subPermission.index" />

    <b-card v-else>
      <div class="custom-search d-flex justify-content-between mb-1">
        <!-- search input -->
        <b-form-group>
          <div class="d-flex align-items-center">
            <label class="mr-1">{{ $t("g.searchLabel") }}</label>
            <b-form-input
              v-model="searchTerm"
              :placeholder="$t('g.searchHere')"
              type="text"
              class="d-inline-block"
            />
          </div>
        </b-form-group>
        <div v-if="subPermission.store">
          <b-button :to="{ name: 'Complaint' }" variant="primary">{{
            $t("g.addComplaint")
          }}</b-button>
        </div>
      </div>

      <!-- table -->
      <vue-good-table
        :columns="columns"
        :rows="rows"
        :rtl="direction"
        styleClass="vgt-table condensed"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm,
        }"
        :select-options="{
          enabled: true,
          selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true, // disable the select info panel on top
          selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
        }"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
      >
        <template slot="table-column" slot-scope="props">
          <!-- Column: Name -->
          <span
            v-if="props.column.label === 'complainant_name'"
            class="text-nowrap"
          >
            {{ $t("g.complainant_name") }}
          </span>
          <span
            v-else-if="props.column.label === 'complainant_phone'"
            class="text-nowrap"
          >
            {{ $t("g.complainant_phone") }}
          </span>
          <span
            v-else-if="props.column.label === 'complainant_email'"
            class="text-nowrap"
          >
            {{ $t("g.complainant_email") }}
          </span>
          <span v-else-if="props.column.label === 'place'" class="text-nowrap">
            {{ $t("g.ComplaintSite") }}
          </span>
          <span
            v-else-if="props.column.label === 'description'"
            class="text-nowrap"
          >
            {{ $t("g.description") }}
          </span>
          <span v-else-if="props.column.label === 'date'" class="text-nowrap">
            {{ $t("g.date") }}
          </span>

          <span
            v-else-if="props.column.label === 'Investigator_name'"
            class="text-nowrap"
          >
            {{ $t("g.InvestigatorName") }}
          </span>

          <span
            v-else-if="props.column.label === 'Investigation_date'"
            class="text-nowrap"
          >
            {{ $t("g.InvestigationDate") }}
          </span>

          <span
            v-else-if="props.column.label === 'Investigation_result'"
            class="text-nowrap"
          >
            {{ $t("g.InvestigationResult") }}
          </span>

          <span
            v-else-if="props.column.label === 'Investigation_notes'"
            class="text-nowrap"
          >
            {{ $t("g.InvestigationNotes") }}
          </span>

          <span
            v-else-if="props.column.label === 'is_positive'"
            class="text-nowrap"
          >
            {{ $t("g.ComplaintStatus") }}
          </span>

          <span v-else-if="props.column.label === 'action'" class="text-nowrap">
            {{ $t("g.action") }}
          </span>
          <span v-else>
            {{ props.column.label }}
          </span>
        </template>

        <!-- Slot: Table Row -->
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field === 'complainant_name_row'">
            <router-link
              v-if="subPermission.show"
              :to="{ name: 'ShowComplaint', params: { id: props.row.id } }"
            >
              <span>
                {{ props.row.complainant_name }}
              </span>
            </router-link>
            <span v-else>{{ props.row.complainant_name }}</span>
          </span>
          <span v-if="props.column.field === 'Investigator_name'">
            {{
              props.row.Investigator
                ? props.row.Investigator.first_name +
                  " " +
                  props.row.Investigator.last_name
                : $t("g.noInvestigator")
            }}
          </span>

          <span v-if="props.column.field === 'description_row'">
            {{ props.row.description.substr(0, 20) + "..." }}
          </span>

          <span v-if="props.column.field === 'date_row'">
            {{ format(props.row.date) }}
          </span>
          <span v-if="props.column.field === 'Investigation_date_row'">
            {{ format(props.row.Investigation_date) }}
          </span>
          <span v-if="props.column.field === 'is_positive_row'">
            {{ props.row.is_positive ? $t("g.positive") : $t("g.malicious") }}
          </span>
          <!-- Column: Action -->
          <span v-if="props.column.field === 'action'">
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
                class="custom-popup"
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>

                <!-- <b-dropdown-item
                  :to="{ name: 'EditComplaint', params: { id: props.row.id } }"
                >
                  <feather-icon icon="EditIcon" class="mr-50" />
                  <span>{{ $t("g.update") }}</span>
                </b-dropdown-item> -->
                <b-dropdown-item
                  v-if="!props.row.Investigator"
                  v-b-modal.assignInvestigator
                  @click="selectId(props.row.id)"
                >
                  <feather-icon icon="UserPlusIcon" class="mr-50" />
                  <span>{{ $t("g.assignInvestigator") }}</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="subPermission.destroy"
                  @click="deleteAlert(props.row.id)"
                >
                  <feather-icon icon="Trash2Icon" class="mr-50" />
                  <span>{{ $t("g.delete") }}</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap">
                {{ $t("g.pagelength") }}
              </span>
              <b-form-select
                v-model="pageLength"
                :options="['3', '5', '10']"
                class="mx-1"
                @input="
                  (value) => props.perPageChanged({ currentPerPage: value })
                "
              />
              <span class="text-nowrap">
                {{ $t("g.of") }} {{ props.total }}
                {{ $t("g.pageText") }}
              </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template> </vue-good-table
    ></b-card>

    <b-modal
      id="assignInvestigator"
      centered
      hide-footer
      :title="$t('g.assignInvestigator')"
    >
      <validation-observer ref="assignInvestigatorForm">
        <form>
          <b-form-group :label="$t('g.InvestigatorName')">
            <validation-provider
              #default="{ errors }"
              name="Investigator Name"
              rules="required"
            >
              <b-form-select
                v-model="form_data.user_id"
                :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                :options="Investigator_options"
                text-field="first_name"
                value-field="id"
                :state="errors.length > 0 ? false : null"
              />

              <small class="text-danger">{{
                errors[0] ? $t("g.alert." + errors[0]) : errors[0]
              }}</small>
            </validation-provider>
          </b-form-group>
        </form>
      </validation-observer>
      <hr />
      <b-button
        @click.prevent="assignInvestigator"
        type="submit"
        variant="primary"
        >{{ $t("g.submit") }}</b-button
      >
    </b-modal>
  </div>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BCard,
  BButton,
  BDropdownItem,
  BDropdown,
  VBTooltip,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import store from "@/store/index";
import Ripple from "vue-ripple-directive";
import Loading from "@/views/components/logic/loading.vue";
import Error from "@/views/components/logic/error.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import formatDate from "@/composables/format-date/format-date";
import ContentNotView from "@/views/components/logic/contentNotView.vue";

export default {
  components: {
    VueGoodTable,
    BAvatar,
    ContentNotView,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BCard,
    BButton,
    Loading,
    Error,
    BDropdownItem,
    BDropdown,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      form_data: {
        user_id: null,
      },
      current_id: null,
      log: [],
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: "complainant_name",
          field: "complainant_name_row",
        },
        {
          label: "place",
          field: "place",
        },
        {
          label: "date",
          field: "date_row",
        },
        {
          label: "Investigator_name",
          field: "Investigator_name",
        },
        {
          label: "Investigation_date",
          field: "Investigation_date_row",
        },
        {
          label: "Investigation_result",
          field: "Investigation_result",
        },
        {
          label: "is_positive",
          field: "is_positive_row",
        },
        {
          label: "action",
          field: "action",
        },
      ],
      rows: [],
      Investigator_options: [
        {
          value: null,
          first_name: this.$t("g.selectInvestigator"),
          disabled: true,
        },
      ],
      searchTerm: "",
      isLoading: true,
      isError: false,
      format: null,
      subPermission: {},
    };
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
  },
  beforeMount() {
    // get data from backend

    this.getUsers();
    this.getComplaints();

    this.format = formatDate;
  },
  methods: {
    getComplaints() {
      this.$http
        .get("admin/complaints")
        .then((res) => {
          this.rows = res.data.data;
          setTimeout(() => {
            this.isLoading = false;
            this.isError = false;
            store.dispatch("GET_PERMISSION", "complaints");
            this.subPermission = store.state.permissions.sub;
          }, 1000);
        })
        .catch((err) => {
          console.log(err);
          this.isLoading = false;
          this.isError = true;
        });
    },
    getUsers() {
      this.$http
        .get(`admin/users`)
        .then((res) => {
          this.Investigator_options.push(...res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    deleteComlaints(id) {
      this.$http
        .delete(`admin/complaints/${id}`)
        .then((res) => {
          this.getComplaints();
        })
        .catch((err) => console.log(err));
    },
    // Sweet Alert
    deleteAlert(id) {
      this.$swal({
        title: this.$t("g.areYouSure?"),
        text: this.$t("g.youWontBeAbleToRevertThis"), // You won't be able to revert this!
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.$t("g.yesDeleteIt"), // "Yes, delete it!" ,
        cancelButtonText: this.$t("g.cancel"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$swal({
            icon: "success",
            title: this.$t("g.deleted"), //"Deleted!"
            text: this.$t("g.yourFileHasBeenDeleted"), //"Your file has been deleted."
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
          this.deleteComlaints(id);
        }
      });
    },

    selectId(id) {
      this.current_id = id;
    },

    //  Sweet Toast Function config
    makeToast(variant = null, body, title) {
      this.$bvToast.toast(this.$t(body), {
        title: this.$t(title),
        variant,
        solid: true,
      });
    },

    assignInvestigator() {
      this.$refs.assignInvestigatorForm.validate().then((success) => {
        if (success) {
          console.log(this.form_data);
          this.submitForm();
        } else {
          this.makeToast(
            "warning",
            this.$t("g.send.invalidInputBody"),
            this.$t("g.send.invalidInput")
          );
        }
      });
    },

    submitForm() {
      let formData = new FormData();
      for (let item in this.form_data) {
        formData.append(item, this.form_data[item]);
      }
      this.$http
        .post(`admin/complaints/${this.current_id}`, formData, {
          params: { _method: "put" },
        })
        .then((res) => {
          this.$bvModal.hide("assignInvestigator");
          this.makeToast(
            "success",
            this.$t("g.send.successBody"),
            this.$t("g.send.successTitle")
          );
          (this.form_data.user_id = null),
            this.$refs.assignInvestigatorForm.reset();
          this.getComplaints();
        })
        .catch((err) => {
          for (const key in err.response.data.errors) {
            this.makeToast(
              "danger",
              err.response.data.errors[key][0],
              this.$t("g.send.errorTitle")
            );
          }
          console.log(err);
        });
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";

.custom-popup {
  .dropdown-menu.show {
    transform: translate3d(-80px, 41px, 0px) !important;
  }
}
</style>
